import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';

const nodes = [
  {
    id: '0',
    name: 'Row 1',
    data: 'Sample data',
  },
  {
    id: '1',
    name: 'Row 2',
    data: 'Sample data',
  },
  {
    id: '2',
    name: 'Row 3',
    data: 'Sample data',
  },
  {
    id: '3',
    name: 'Row 4',
    data: 'Sample data',
  },
];

const defaultTableColumns = [
  { label: '', renderCell: (item) => item.name },
  {
    label: 'Column 1',
    renderCell: (item) => item.data,
  },
  { label: 'Column 2', renderCell: (item) => item.data },
  {
    label: 'Column 3',
    renderCell: (item) => item.data,
  },
  { label: 'Column 4', renderCell: (item) => item.data },
];

function HtmlBuilderCreateTable({ previewDc, updateTableHTML, tableSettings, tableBorderState, updateInlineStyles }) {
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState({ nodes });
  const [columns, setColumns] = useState([]);

  const convertArrayToColumns = (headers) => {
    return headers.map((header) => ({
      label: header,
      renderCell: (item) => item[header.toLowerCase().replace(/ /g, '_')],
    }));
  };

  const convertArrayToData = (array) => {
    const headers = array[0];
    const dataRows = array.slice(1).map((row) => {
      return row.reduce((acc, cell, index) => {
        const key = headers[index].toLowerCase().replace(/ /g, '_');
        acc[key] = cell;
        return acc;
      }, {});
    });

    return { nodes: dataRows };
  };

  /*******************************************************/
  /******************* STYLE FUNCTIONS *******************/
  /*******************************************************/

  function getHeaderRowBorderTopStyle() {
    if (tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.headerRow.top || tableBorderState.headerRow.all || tableBorderState.headerRow.outside
          ? tableSettings.headerRowBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.headerRowBorderColor}`;
    } else {
      const thickness =
        tableBorderState.table.top || tableBorderState.table.all || tableBorderState.table.outside
          ? tableSettings.tableBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
  }

  function getHeaderRowBorderBottomStyle() {
    if (tableSettings.headerColumnStyling && !tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.table.inside || tableBorderState.table.horizontal || tableBorderState.table.all
          ? tableSettings.tableBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
    if (tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.headerRow.bottom || tableBorderState.headerRow.all || tableBorderState.headerRow.outside
          ? tableSettings.headerRowBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.headerRowBorderColor}`;
    } else {
      const thickness =
        tableBorderState.table.inside || tableBorderState.table.horizontal ? tableSettings.tableBorderThickness : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
  }

  function getHeaderRowBorderInsideStyle() {
    if (tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.headerRow.vertical || tableBorderState.headerRow.all || tableBorderState.headerRow.inside
          ? tableSettings.headerRowBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.headerRowBorderColor}`;
    } else {
      const thickness =
        tableBorderState.table.vertical || tableBorderState.table.all || tableBorderState.table.inside
          ? tableSettings.tableBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
  }

  function getHeaderRowBorderRightStyle() {
    if (tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.headerRow.right || tableBorderState.headerRow.all || tableBorderState.headerRow.outside
          ? tableSettings.headerRowBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.headerRowBorderColor}`;
    } else {
      const thickness =
        tableBorderState.table.right || tableBorderState.table.all || tableBorderState.table.outside
          ? tableSettings.tableBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
  }

  function getHeaderRowBorderLeftStyle() {
    if (tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.headerRow.left || tableBorderState.headerRow.all || tableBorderState.headerRow.outside
          ? tableSettings.headerRowBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.headerRowBorderColor}`;
    } else {
      const thickness =
        tableBorderState.table.left || tableBorderState.table.all || tableBorderState.table.outside
          ? tableSettings.tableBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
  }

  function getTableBorderTopStyle() {
    if (tableSettings.headerRowStyling) {
      const thickness =
        tableBorderState.table.top || tableBorderState.table.all || tableBorderState.table.outside
          ? tableSettings.tableBorderThickness
          : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    } else {
      const thickness = tableBorderState.table.all ? tableSettings.tableBorderThickness : '0px';
      return `${thickness} solid ${tableSettings.tableBorderColor}`;
    }
  }

  function getTableBorderHorizontalStyle() {
    const thickness =
      tableBorderState.table.horizontal || tableBorderState.table.all || tableBorderState.table.inside
        ? tableSettings.tableBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.tableBorderColor}`;
  }

  function getTableBorderVerticalStyle() {
    const thickness =
      tableBorderState.table.vertical || tableBorderState.table.all || tableBorderState.table.inside
        ? tableSettings.tableBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.tableBorderColor}`;
  }

  function getTableBorderLeftStyle() {
    const thickness =
      tableBorderState.table.left || tableBorderState.table.all || tableBorderState.table.outside
        ? tableSettings.tableBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.tableBorderColor}`;
  }

  function getTableBorderRightStyle() {
    const thickness =
      tableBorderState.table.right || tableBorderState.table.all || tableBorderState.table.outside
        ? tableSettings.tableBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.tableBorderColor}`;
  }

  function getTableBorderBottomStyle() {
    const thickness =
      tableBorderState.table.bottom || tableBorderState.table.all || tableBorderState.table.outside
        ? tableSettings.tableBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.tableBorderColor}`;
  }

  function getHeaderColumnBorderLeftStyle() {
    const thickness =
      tableBorderState.headerColumn.left || tableBorderState.headerColumn.all || tableBorderState.headerColumn.outside
        ? tableSettings.headerColumnBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.headerColumnBorderColor}`;
  }

  function getHeaderColumnBorderRightStyle() {
    const thickness =
      tableBorderState.headerColumn.right || tableBorderState.headerColumn.all || tableBorderState.headerColumn.outside
        ? tableSettings.headerColumnBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.headerColumnBorderColor}`;
  }

  function getCornerCellBorderBottomStyle() {
    const thickness =
      tableBorderState.headerColumn.all ||
      tableBorderState.headerColumn.inside ||
      tableBorderState.headerColumn.horizontal
        ? tableSettings.headerColumnBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.headerColumnBorderColor}`;
  }

  function getHeaderColumnBorderBottomStyle() {
    const thickness =
      tableBorderState.headerColumn.bottom || tableBorderState.headerColumn.all || tableBorderState.headerColumn.outside
        ? tableSettings.headerColumnBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.headerColumnBorderColor}`;
  }

  function getHeaderColumnBorderTopStyle() {
    const thickness =
      tableBorderState.headerColumn.top || tableBorderState.headerColumn.all || tableBorderState.headerColumn.outside
        ? tableSettings.headerColumnBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.headerColumnBorderColor}`;
  }

  function getHeaderColumnBorderHorizontalStyle() {
    const thickness =
      tableBorderState.headerColumn.horizontal ||
      tableBorderState.headerColumn.all ||
      tableBorderState.headerColumn.inside
        ? tableSettings.headerColumnBorderThickness
        : '0px';
    return `${thickness} solid ${tableSettings.headerColumnBorderColor}`;
  }

  function getHeaderRowPadding() {
    if (tableSettings.headerRowStyling) {
      return `${tableSettings.headerRowCellPaddingTop} ${tableSettings.headerRowCellPaddingRight} 
      ${tableSettings.headerRowCellPaddingBottom} ${tableSettings.headerRowCellPaddingLeft}`;
    } else {
      return getTableCellPadding();
    }
  }

  function getTableCellPadding() {
    return `${tableSettings.tableCellPaddingTop} ${tableSettings.tableCellPaddingRight} 
    ${tableSettings.tableCellPaddingBottom} ${tableSettings.tableCellPaddingLeft}`;
  }

  function getHeaderColumnPadding() {
    return `${tableSettings.headerColumnCellPaddingTop} ${tableSettings.headerColumnCellPaddingRight} 
    ${tableSettings.headerColumnCellPaddingBottom} ${tableSettings.headerColumnCellPaddingLeft}`;
  }

  function getHeaderRowVerticalAlign() {
    const height = tableSettings.headerRowStyling
      ? tableSettings.headerRowVerticalAlign === 'top'
        ? '100%'
        : 'auto'
      : tableSettings.tableVerticalAlign === 'top'
      ? '100%'
      : 'auto';
    return height;
  }

  function getHeaderRowStyle() {
    let style = '';
    if (tableSettings.headerColumnStyling && !tableSettings.headerRowStyling) {
      style += `
      .th:first-of-type {
        border-left: ${getHeaderColumnBorderLeftStyle()};
        border-right: ${getHeaderColumnBorderRightStyle()};
        border-top: ${getHeaderColumnBorderTopStyle()};
        border-bottom: ${getCornerCellBorderBottomStyle()};
        padding: ${getHeaderColumnPadding()};
        white-space: normal;
        word-wrap: break-word;
        background-color: ${tableSettings.headerColumnBackgroundColor};
        color: ${tableSettings.headerColumnFontColor};
        font-weight: ${tableSettings.headerColumnFontWeight};
        text-transform: ${tableSettings.headerColumnTextTransform};
      }
      .th:first-of-type > div {
        text-align: ${tableSettings.headerColumnHorizontalAlign};
        // corner cell vertical align
        height: ${tableSettings.headerColumnVerticalAlign === 'top' ? '100%' : 'auto'};
        text-wrap: wrap;
        word-break: break-word;
      }
      .th:not(:first-of-type) > div {
        text-align: ${tableSettings.tableHorizontalAlign};
        // header row vertical align
        height: ${getHeaderRowVerticalAlign()};
        text-wrap: wrap;
        word-break: break-word;
      }
      .th:not(:first-of-type) {
        background-color: ${tableSettings.tableBackgroundColor};
        font-size: ${tableSettings.tableFontSize};
        color: ${tableSettings.tableFontColor};
        font-weight: ${tableSettings.tableFontWeight};
        white-space: normal;
        word-wrap: break-word;
        padding: ${getHeaderRowPadding()};
        border-bottom: ${getHeaderRowBorderBottomStyle()};
        border-top: ${getHeaderRowBorderTopStyle()};
      }
      // inner grid
      th:nth-of-type(2) {
        border-left: ${getHeaderRowBorderLeftStyle()};
      }
      // inner grid
      .th:not(:last-of-type):not(:first-of-type) {
        border-right: ${getHeaderRowBorderInsideStyle()};
      }
      // outer grid right
      .th:last-of-type {
        border-right: ${getHeaderRowBorderRightStyle()};
      }
    `;
    } else {
      style += `
      background-color: ${
        tableSettings.headerRowStyling ? tableSettings.headerRowBackgroundColor : tableSettings.tableBackgroundColor
      };
      font-size: ${tableSettings.headerRowStyling ? tableSettings.headerRowFontSize : tableSettings.tableFontSize};
      .th > div {
        text-align: ${
          tableSettings.headerRowStyling ? tableSettings.headerRowHorizontalAlign : tableSettings.tableHorizontalAlign
        };
        // header row vertical align
        height: ${getHeaderRowVerticalAlign()};
        text-wrap: wrap;
        word-break: break-word;
      }
      .th {
        color: ${tableSettings.headerRowStyling ? tableSettings.headerRowFontColor : tableSettings.tableFontColor};
        font-weight: ${
          tableSettings.headerRowStyling ? tableSettings.headerRowFontWeight : tableSettings.tableFontWeight
        };
        white-space: normal;
        word-wrap: break-word;
        padding: ${getHeaderRowPadding()};
        border-bottom: ${getHeaderRowBorderBottomStyle()};
        border-top: ${getHeaderRowBorderTopStyle()};
        text-transform: ${
          tableSettings.headerRowStyling ? tableSettings.headerRowTextTransform : tableSettings.tableTextTransform
        };
      }
      // inner grid
      .th:not(:last-of-type) {
        border-right: ${getHeaderRowBorderInsideStyle()};
      }
      // outer grid right
      .th:last-of-type {
        border-right: ${getHeaderRowBorderRightStyle()};
      }
      // outer grid left
      .th:first-of-type {
        border-left: ${getHeaderRowBorderLeftStyle()};
      }
    `;
    }
    return style;
  }

  function getRowStyle() {
    let style = '';
    // styling of the main table rows while header column styling is on
    let headerColumnStylingTableCss = `
        .td:not(:first-of-type) {
          text-align: ${tableSettings.tableHorizontalAlign};
          padding: ${getTableCellPadding()};
          font-size: ${tableSettings.tableFontSize};
          font-weight: ${tableSettings.tableFontWeight};
      `;
    // styling of main table rows while header column styling is off
    let tableCss = `
      .td {
        text-align: ${tableSettings.tableHorizontalAlign};
        padding: ${getTableCellPadding()};
        font-size: ${tableSettings.tableFontSize};
        font-weight: ${tableSettings.tableFontWeight};
      `;
    // background colors
    if (tableSettings.tableStripedBackground) {
      style += `
        &:nth-of-type(odd) {
          background-color: ${tableSettings.tableBackgroundColor};
        }

        &:nth-of-type(even) {
          background-color: ${tableSettings.tableSecondaryBackgroundColor};
        }
      `;
      // insert applicable main table styling with no background color
      if (tableSettings.headerColumnStyling) {
        style += headerColumnStylingTableCss + '}';
      } else {
        style += tableCss + '}';
      }
    } else {
      // insert applicable main table styling with background color
      if (tableSettings.headerColumnStyling) {
        style += headerColumnStylingTableCss + `background-color: ${tableSettings.tableBackgroundColor}; }`;
      } else {
        style += tableCss + `background-color: ${tableSettings.tableBackgroundColor}; }`;
      }
    }
    if (tableSettings.headerColumnStyling) {
      style += `
      .td > div {
        text-wrap: wrap;
        word-break: break-word;
      }
      // table vertical align
      .td:not(:first-of-type) > div {
        height: ${tableSettings.tableVerticalAlign === 'top' ? '100%' : 'auto'};
      }
      // partial table horizontal inner grid
      &:not(:last-of-type) > .td {
        border-bottom: ${getTableBorderHorizontalStyle()};
      }
      // partial table vertical inner grid
      .td:not(:last-of-type) {
        border-right: ${getTableBorderVerticalStyle()};
      }
      // partial table outer grid left
      td:nth-of-type(2),
      th:nth-of-type(2)  {
        border-left: ${getTableBorderLeftStyle()};
      }
      // partial table outer grid right
      .td:last-of-type {
        border-right: ${getTableBorderRightStyle()};
      }
      // partial table outer grid bottom
      &:last-of-type > .td {
        border-bottom: ${getTableBorderBottomStyle()};
      }
      // header column styling
      .td:first-of-type {
        color: ${tableSettings.headerColumnFontColor};
        background-color: ${tableSettings.headerColumnBackgroundColor};
        text-align: ${tableSettings.headerColumnHorizontalAlign};
        padding: ${getHeaderColumnPadding()};
        font-size: ${tableSettings.headerColumnFontSize};
        font-weight: ${tableSettings.headerColumnFontWeight};
        border-left: ${getHeaderColumnBorderLeftStyle()};
        border-right: ${getHeaderColumnBorderRightStyle()};
        text-transform: ${tableSettings.headerColumnTextTransform};
      }
      // header column vertical align
      .td:first-of-type > div {
        height: ${tableSettings.headerColumnVerticalAlign === 'top' ? '100%' : 'auto'};
      }
    `;
    } else {
      style += `
        .td > div {
          height: ${tableSettings.tableVerticalAlign === 'top' ? '100%' : 'auto'};
          text-wrap: wrap;
          word-break: break-word;
        }
        // horizontal inner grid
        &:not(:last-of-type) > .td {
          border-bottom: ${getTableBorderHorizontalStyle()};
        }
        // vertical inner grid
        .td:not(:last-of-type) {
          border-right: ${getTableBorderVerticalStyle()};
        }
        // outer grid right
        .td:last-of-type {
          border-right: ${getTableBorderRightStyle()};
        }
        // outer grid left
        .td:first-of-type {
          border-left: ${getTableBorderLeftStyle()};
        }
        // outer grid bottom
        &:last-of-type > .td {
          border-bottom: ${getTableBorderBottomStyle()};
        }
      `;
    }
    return style;
  }

  function getTableStyle() {
    let style = `
        width: ${tableSettings.responsiveTable ? tableSettings.percentWidth : tableSettings.pxWidth};
        color: ${tableSettings.tableFontColor};
        font-family: ${tableSettings.tableFontFamily};
        text-transform: ${tableSettings.tableTextTransform};
        table-layout: fixed;
      `;

    if (tableSettings.headerColumnStyling) {
      style += `
        // header column border bottom
        tr:last-of-type td:first-of-type {
          border-bottom: ${getHeaderColumnBorderBottomStyle()};
        }
        // header column horizontal inner grid
        tr:not(:last-of-type) td:first-of-type {
          border-bottom: ${getHeaderColumnBorderHorizontalStyle()};
        }
      `;
      if (tableSettings.headerRowStyling) {
        style += `
        // header column border top
        tr:first-of-type td:first-of-type {
          border-top: ${getHeaderColumnBorderTopStyle()};
        }
        `;
      }
    }

    return style;
  }

  function getBaseRowStyle() {
    let style = '';
    if (!(tableSettings.headerColumnStyling && !tableSettings.headerRowStyling)) {
      style += `
      // outer grid top
        &:first-of-type .td {
          border-top: ${getTableBorderTopStyle()};
        }
      `;
    }

    return style;
  }

  /******************************************************/
  /******************* GENERATE THEME *******************/
  /******************************************************/

  const generateTableTheme = () => ({
    Table: `${getTableStyle()}`,
    Body: '',
    HeaderRow: `${getHeaderRowStyle()}`,
    Row: `${getRowStyle()}`,
    BaseRow: `${getBaseRowStyle()}`,
    HeaderCell: `
      font-weight: bold;
    `,
  });

  /*******************************************************/
  /****************** INLINE-CSS STYLES ******************/
  /*******************************************************/

  // This function generates the inline CSS styles for the table to be used
  // when generating the final table for email in the backend.
  function generateInlineCssStyles() {
    let inlineCss = '';
    if (tableSettings.headerColumnStyling && !tableSettings.headerRowStyling) {
      inlineCss += `
      <style>table { 
        width: ${tableSettings.responsiveTable ? tableSettings.percentWidth : tableSettings.pxWidth}; 
        font-family:${tableSettings.tableFontFamily}; 
        border-spacing:0px; 
        color: ${tableSettings.tableFontColor};
        text-transform: ${tableSettings.tableTextTransform};
        table-layout: fixed;
      }
      th:first-of-type {
        border-left: ${getHeaderColumnBorderLeftStyle()};
        border-right: ${getHeaderColumnBorderRightStyle()};
        border-top: ${getHeaderColumnBorderTopStyle()};
        border-bottom: ${getCornerCellBorderBottomStyle()};
        padding: ${getHeaderColumnPadding()};
        white-space: normal;
        word-wrap: break-word;
        background-color: ${tableSettings.headerColumnBackgroundColor};
        color: ${tableSettings.headerColumnFontColor};
        font-weight: ${tableSettings.headerColumnFontWeight};
        text-transform: ${tableSettings.headerColumnTextTransform};
      }
      th:first-of-type > div {
        text-align: ${tableSettings.headerColumnHorizontalAlign};
        height: ${tableSettings.headerColumnVerticalAlign === 'top' ? '100%' : 'auto'};
        text-wrap: wrap;
        word-break: break-word;
      }
      th:not(:first-of-type) > div {
        text-align: ${tableSettings.tableHorizontalAlign};
        height: ${getHeaderRowVerticalAlign()};
        text-wrap: wrap;
        word-break: break-word;
      }
      th:not(:first-of-type) {
        background-color: ${tableSettings.tableBackgroundColor};
        font-size: ${tableSettings.tableFontSize};
        font-weight: ${tableSettings.tableFontWeight};
        white-space: normal;
        word-wrap: break-word;
        padding: ${getHeaderRowPadding()};
        border-bottom: ${getHeaderRowBorderBottomStyle()};
        border-top: ${getHeaderRowBorderTopStyle()};
      }
      th:nth-of-type(2) {
        border-left: ${getHeaderRowBorderLeftStyle()};
      }
      th:not(:last-of-type):not(:first-of-type) {
        border-right: ${getHeaderRowBorderInsideStyle()};
      }
      th:last-of-type {
        border-right: ${getHeaderRowBorderRightStyle()};
      }
    `;
    } else {
      inlineCss += `
      <style>table { 
        width: ${tableSettings.responsiveTable ? tableSettings.percentWidth : tableSettings.pxWidth}; 
        font-family:${tableSettings.tableFontFamily}; 
        border-spacing:0px; 
        color:${tableSettings.tableFontColor};
        text-transform: ${tableSettings.tableTextTransform};
        table-layout: fixed;
      }
      thead { 
        background-color:${
          tableSettings.headerRowStyling ? tableSettings.headerRowBackgroundColor : tableSettings.tableBackgroundColor
        }; 
        font-size:${tableSettings.headerRowStyling ? tableSettings.headerRowFontSize : tableSettings.tableFontSize} 
      }
      th > div { 
        text-align:${
          tableSettings.headerRowStyling ? tableSettings.headerRowHorizontalAlign : tableSettings.tableHorizontalAlign
        }; 
        text-wrap:wrap; 
        word-break:break-word; 
      }
      th { 
        color:${tableSettings.headerRowStyling ? tableSettings.headerRowFontColor : tableSettings.tableFontColor}; 
        font-weight:${
          tableSettings.headerRowStyling ? tableSettings.headerRowFontWeight : tableSettings.tableFontWeight
        }; 
        vertical-align:${
          tableSettings.headerRowStyling ? tableSettings.headerRowVerticalAlign : tableSettings.tableVerticalAlign
        }; 
        white-space:normal; 
        word-break:break-word; 
        padding: ${getHeaderRowPadding()}; 
        border-bottom: ${getHeaderRowBorderBottomStyle()}; 
        border-top: ${getHeaderRowBorderTopStyle()}; 
        text-transform: ${
          tableSettings.headerRowStyling ? tableSettings.headerRowTextTransform : tableSettings.tableTextTransform
        };
      }
      th:not(:last-of-type) { 
        border-right: ${getHeaderRowBorderInsideStyle()}; 
      }
      th:last-of-type { 
        border-right: ${getHeaderRowBorderRightStyle()}; 
      }
      th:first-of-type { 
        border-left: ${getHeaderRowBorderLeftStyle()}; 
      }`;
      if (!(tableSettings.headerColumnStyling && !tableSettings.headerRowStyling)) {
        inlineCss += `
        tr:first-of-type td { 
          border-top: ${getTableBorderTopStyle()}; 
        }`;
      }
    }

    // styling of the main table rows while header column styling is on
    let headerColumnStylingTableCss = `
      td:not(:first-of-type) { 
        text-align:${tableSettings.tableHorizontalAlign}; 
        padding:${getTableCellPadding()}; 
        font-size:${tableSettings.tableFontSize}; 
        font-weight:${tableSettings.tableFontWeight};
    `;
    // styling of main table rows while header column styling is off
    let tableCss = `
      td { 
        text-align:${tableSettings.tableHorizontalAlign}; 
        padding:${getTableCellPadding()}; 
        font-size:${tableSettings.tableFontSize}; 
        font-weight:${tableSettings.tableFontWeight};
      `;
    // background colors
    if (tableSettings.tableStripedBackground) {
      inlineCss += `
      tbody tr:nth-of-type(odd) { 
        background-color: ${tableSettings.tableBackgroundColor}; 
      }
      tbody tr:nth-of-type(even) { 
        background-color: ${tableSettings.tableSecondaryBackgroundColor}; 
      }
      `;
      // insert applicable main table styling with no background color
      if (tableSettings.headerColumnStyling) {
        inlineCss += headerColumnStylingTableCss + '}';
      } else {
        inlineCss += tableCss + '}';
      }
    } else {
      // insert applicable main table styling with background color
      if (tableSettings.headerColumnStyling) {
        inlineCss +=
          headerColumnStylingTableCss +
          `background-color: ${tableSettings.tableBackgroundColor}; 
      }`;
      } else {
        inlineCss +=
          tableCss +
          `background-color: ${tableSettings.tableBackgroundColor}; 
      }`;
      }
    }

    if (tableSettings.headerColumnStyling) {
      inlineCss += `
      td > div { 
        text-wrap:wrap; 
        word-break:break-word; 
      }
      td:not(:first-of-type) { 
        vertical-align:${tableSettings.tableVerticalAlign}; 
      }
      :not(:last-of-type) > td { 
        border-bottom: ${getTableBorderHorizontalStyle()}; 
      }
      td:not(:last-of-type) { 
        border-right: ${getTableBorderVerticalStyle()}; 
      }
      td:nth-of-type(2), tr:nth-of-type(2) { 
        border-left: ${getTableBorderLeftStyle()}; 
      }
      td:last-of-type { 
        border-right: ${getTableBorderRightStyle()}; 
      }
      :last-of-type > td { 
        border-bottom: ${getTableBorderBottomStyle()}; 
      }
      td:first-of-type { 
        color: ${tableSettings.headerColumnFontColor}; 
        background-color:${tableSettings.headerColumnBackgroundColor}; 
        text-align:${tableSettings.headerColumnHorizontalAlign}; 
        padding:${getHeaderColumnPadding()}; 
        font-size:${tableSettings.headerColumnFontSize}; 
        font-weight:${tableSettings.headerColumnFontWeight}; 
        border-left:${getHeaderColumnBorderLeftStyle()}; 
        border-right:${getHeaderColumnBorderRightStyle()}; 
        text-transform: ${tableSettings.headerColumnTextTransform};
      }
      td:first-of-type { 
        vertical-align:${tableSettings.headerColumnVerticalAlign}; 
      }
      tr:last-of-type td:first-of-type { 
        border-bottom:${getHeaderColumnBorderBottomStyle()}; 
      }
      tr:not(:last-of-type) td:first-of-type { 
        border-bottom:${getHeaderColumnBorderHorizontalStyle()}; 
      }`;
      if (tableSettings.headerRowStyling) {
        inlineCss += `
        tr:first-of-type td:first-of-type {
          border-top: ${getHeaderColumnBorderTopStyle()};
        }
        `;
      }
      inlineCss += '</style>';
    } else {
      inlineCss += `
      td { 
        vertical-align:${tableSettings.tableVerticalAlign}; 
      }
      td > div { 
        text-wrap:wrap; 
        word-break:break-word; 
        vertical-align:${tableSettings.tableVerticalAlign}; 
      }
      :not(:last-of-type) > td { 
        border-bottom: ${getTableBorderHorizontalStyle()}; 
      }
      td:not(:last-of-type) { 
        border-right: ${getTableBorderVerticalStyle()}; 
      }
      td:last-of-type { 
        border-right: ${getTableBorderRightStyle()}; 
      }
      td:first-of-type { 
        border-left: ${getTableBorderLeftStyle()}; 
      }
      :last-of-type > td { 
        border-bottom: ${getTableBorderBottomStyle()}; 
      }</style>`;
    }
    return inlineCss;
  }

  const theme = useTheme(generateTableTheme());
  const inlineStyles = generateInlineCssStyles();

  // handle changes in previewDc
  useEffect(() => {
    if (previewDc && previewDc.length > 0) {
      // Set columns from the first row of previewDc
      const newColumns = convertArrayToColumns(previewDc[0]);
      setColumns(newColumns);

      // Set data from the remaining rows of previewDc
      const newData = convertArrayToData(previewDc);
      setTableData(newData);
    } else {
      // Fallback to default data if previewDc is empty
      setColumns(defaultTableColumns);
      setTableData({ nodes: nodes });
    }
  }, [previewDc]);

  // handle table HTML update
  useEffect(() => {
    setTimeout(() => {
      if (tableRef.current) {
        const innerHTML = tableRef.current.innerHTML;
        updateTableHTML(
          `<table style="width: ${
            tableSettings.responsiveTable ? tableSettings.percentWidth : tableSettings.pxWidth
          }; font-family: ${tableSettings.tableFontFamily};table-layout:fixed;">${innerHTML}</table>`,
        );
      }
      updateInlineStyles(inlineStyles);
    }, 0);
  }, [tableRef, tableData, updateTableHTML]);

  return (
    <CompactTable key={JSON.stringify(tableData)} ref={tableRef} columns={columns} data={tableData} theme={theme} />
  );
}

HtmlBuilderCreateTable.propTypes = {
  previewDc: PropTypes.array,
  updateTableHTML: PropTypes.func.isRequired,
  updateInlineStyles: PropTypes.func.isRequired,
  tableSettings: PropTypes.object.isRequired,
  tableBorderState: PropTypes.object.isRequired,
};

export default HtmlBuilderCreateTable;
