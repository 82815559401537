import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import { Select } from '../../../shared/FormSelect';
import { cloneDeep } from 'lodash';

class LookerPivots extends Component {
  render() {
    const pivots = this.props.pivots || [];
    let body = (
      <a href="#dummy" onClick={this.addPivot}>
        Add Pivot
      </a>
    );
    if (pivots && pivots.length > 0) {
      body = pivots.map((pivot, idx) => {
        return this.renderPivot(pivot, pivots, idx);
      });
    }
    return (
      <Form.Field className="mbl">
        <Form.Label>Pivot result</Form.Label>
        <Form.Help>Select fields to pivot by.</Form.Help>
        <Form.Control>{body}</Form.Control>
      </Form.Field>
    );
  }

  renderPivot(pivot, pivots, idx) {
    return (
      <Form.Field kind="group" key={`${idx}_pivot`} className="mbn">
        <AddRemoveButtons
          idx={idx}
          includeAdd={idx === pivots.length - 1}
          removeEntity={this.removePivot}
          addEntity={this.addPivot}
          isReadOnly={this.props.isReadOnly}
        >
          <Form.Control style={{ flex: '6.5 1' }}>
            <Select
              value={{ label: pivot, value: pivot }}
              name="pivot"
              classNamePrefix="matik-select"
              isDisabled={this.props.isReadOnly}
              onChange={(obj, action) => this.onPivotChange(obj, action, idx)}
              aria-label="Select Looker Field to Pivot By"
              options={this.props.fields.map((field) => ({ label: field, value: field }))}
            />
          </Form.Control>
        </AddRemoveButtons>
      </Form.Field>
    );
  }

  onPivotChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const pivot = obj.value;
      const updatedPivots = cloneDeep(this.props.pivots) || [];
      updatedPivots[idx] = pivot;
      this.props.onPivotsUpdate(updatedPivots);
    }
  };

  removePivot = (e, idx) => {
    e.preventDefault();
    let updatedPivots = cloneDeep(this.props.pivots) || [];
    let pivots = [];
    for (let i = 0; i < updatedPivots.length; i++) {
      if (i !== idx) {
        pivots.push(updatedPivots[i]);
      }
    }
    updatedPivots = pivots;
    this.props.onPivotsUpdate(updatedPivots);
  };

  addPivot = (e) => {
    e.preventDefault();
    const updatedPivots = cloneDeep(this.props.pivots) || [];
    updatedPivots.push(this.props.fields[0]);
    this.props.onPivotsUpdate(updatedPivots);
  };
}

LookerPivots.propTypes = {
  fields: PropTypes.array,
  isReadOnly: PropTypes.bool,
  pivots: PropTypes.array,
  onPivotsUpdate: PropTypes.func,
};

export default LookerPivots;
