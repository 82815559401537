import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ApiReturnFields from './ApiReturnFields';
import LookerPivots from './LookerPivots';
import LookerTimezone from './LookerTimezone';
import LookerApplyFormatting from './LookerApplyFormatting';
import LookerOutputScreenshot from './LookerOutputScreenshot';
import Constants from '../../../Constants';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { cloneDeep } from 'lodash';
import ApiSorts from './ApiSorts';
import ApiLimit from './ApiLimit';
import ApiFilters from './ApiFilters';

function LookerQueryForm({ dynamicFields, entityType, fields, input, inputMapping, onInputMappingUpdate, queryObj }) {
  const dynamicContentContext = useContext(DynamicContentContext);

  const onSortsUpdate = (sortsArray) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query.sorts = sortsArray;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onLimitUpdate = (limitValue) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query.limit = limitValue;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onFilterUpdate = (filterArray) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query.filters = filterArray;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onPivotsUpdate = (pivots) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query.pivots = pivots;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onTimezoneUpdate = (timezone) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query.query_timezone = timezone;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onApplyFormatting = (applyFormatting) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.query.apply_formatting = applyFormatting;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  return (
    <React.Fragment>
      <ApiFilters
        filterArray={queryObj.query.filters}
        allFields={fields}
        supportedOperators={Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE[Constants.DATA_SOURCE_TYPES.looker]}
        inputs={dynamicContentContext.existingInputs}
        isReadOnly={dynamicContentContext.isReadOnly}
        isInputPopoverDisabled={entityType === 'input'}
        onFilterUpdate={onFilterUpdate}
      />
      <ApiSorts
        allFields={fields}
        sortsArray={queryObj.query.sorts}
        isReadOnly={dynamicContentContext.isReadOnly}
        onSortsUpdate={onSortsUpdate}
      />
      <LookerPivots
        pivots={queryObj.query.pivots}
        fields={fields}
        isReadOnly={dynamicContentContext.isReadOnly}
        onPivotsUpdate={onPivotsUpdate}
      />
      <ApiLimit
        limitValue={queryObj.query.limit}
        isReadOnly={dynamicContentContext.isReadOnly}
        onLimitUpdate={onLimitUpdate}
      />
      <LookerTimezone
        timezone={queryObj.query.query_timezone}
        isReadOnly={dynamicContentContext.isReadOnly}
        onTimezoneUpdate={onTimezoneUpdate}
      />
      <ApiReturnFields
        queryObj={queryObj}
        fields={fields}
        dynamicFields={dynamicFields}
        entityType={entityType}
        input={input}
        inputMapping={inputMapping}
        onInputMappingUpdate={onInputMappingUpdate}
      />
      <LookerApplyFormatting
        applyFormatting={queryObj.query.apply_formatting}
        isReadOnly={dynamicContentContext.isReadOnly}
        onApplyFormatting={onApplyFormatting}
      />
      {dynamicContentContext.dynamicContentType === Constants.DynamicContentTypes.IMAGE && (
        <LookerOutputScreenshot
          queryObj={queryObj}
          updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
          isReadOnly={dynamicContentContext.isReadOnly}
        />
      )}
    </React.Fragment>
  );
}

LookerQueryForm.contextType = DynamicContentContext;

LookerQueryForm.propTypes = {
  apiInfo: PropTypes.object,
  dynamicFields: PropTypes.array,
  fields: PropTypes.array,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
};

export default LookerQueryForm;
