import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from '../../../shared/CheckboxWithLabel';

const LookerApplyFormatting = (props) => {
  const applyFormatting = props.applyFormatting || false;
  const onChange = (e) => {
    const value = e.target.checked;
    props.onApplyFormatting(value);
  };
  return (
    <div className="mbl">
      <CheckboxWithLabel
        checked={applyFormatting}
        id="looker-apply-formatting"
        name="looker-apply-formatting"
        disabled={props.isReadOnly}
        label="Apply Looker Formatting"
        onChange={onChange}
      />
    </div>
  );
};

LookerApplyFormatting.propTypes = {
  isReadOnly: PropTypes.bool,
  applyFormatting: PropTypes.bool,
  onApplyFormatting: PropTypes.func,
};

export default LookerApplyFormatting;
